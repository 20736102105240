import React from "react"

const Scraping = () => {
  return (
    <>
      <div class="style-div">
        <p>Text 1</p>
      </div>
      <div class="style-div">
        <p>Text 2</p>
      </div>
      <div>
        <p id="p-paragrafo">Text 3</p>
      </div>
    </>
  )
}

export default Scraping
